import { TYPE_NAMES } from "../constants/type_names";

import { Type } from "./Type";

import { MustBeTrueRule } from "../rules/MustBeTrueRule";

class BooleanType extends Type {
  static get _supportedRules(): Set<string> {
    return new Set([MustBeTrueRule.ruleName]);
  }

  static get typeName(): string {
    return TYPE_NAMES.BOOLEAN;
  }

  static check(value: any): boolean {
    return typeof value === typeof true;
  }
}

export { BooleanType };
