import { ValidatorFactory } from "validation-js";
import { IValidationObjectSpec } from "validation-js/lib/validator/interfaces";

import { IActionObjectValidationOutcome } from "./types";

class ActionObject {
  static get schema(): IValidationObjectSpec {
    throw new Error("Not implemented");
  }

  static validate(data: any): IActionObjectValidationOutcome {
    const validator = ValidatorFactory.make(this.schema);
    const valid = validator.validate(data);
    return {
      errors: validator.errors,
      valid,
    };
  }

  static fromJson(data: any): ActionObject {
    const validationOutcome = this.validate(data);
    if (!validationOutcome.valid) {
      throw validationOutcome.errors;
    }
    const res = new this();
    const objSchema = this.schema.schema;
    for (const key in objSchema) {
      if (key in objSchema) {
        // @ts-ignore
        res[key] = data[key];
      }
    }
    return res;
  }

  toJson(): any {
    const data = {};
    const cls = this.constructor as typeof ActionObject;
    const objSchema = cls.schema.schema;
    for (const key in objSchema) {
      if (key in objSchema && key in this) {
        // @ts-ignore
        data[key] = this[key];
      }
    }
    const validationOutcome = cls.validate(data);
    if (!validationOutcome.valid) {
      throw validationOutcome.errors;
    }
    return data;
  }
}

export { ActionObject };
