import { Rule } from "./Rule";

import { IValidationSpec, ValidationData } from "../validator/interfaces";

import { getType } from "../utils/Base64EncodedFileUtils";

class FileTypeRule extends Rule {
  static get ruleName(): string {
    return "file_type";
  }

  get params(): string[] {
    return [this.type];
  }

  type: string;

  constructor(params: { alias: string; spec: IValidationSpec; type: string }) {
    super(params);
    this.type = params.type;
  }

  static parse(
    alias: string,
    spec: IValidationSpec,
    paramsString: string
  ): Rule {
    return new this({ alias, spec, type: paramsString });
  }

  apply = (data: ValidationData): boolean => {
    const value = data as string;
    return getType(value) === this.type;
  };
}

export { FileTypeRule };
