import { validate } from "./validate";

function asBoolean(
  name: string,
  value: any,
  rules: string | string[] = []
): boolean {
  const schema = {
    type: "boolean",
    rules: rules,
  };
  if (/false/i.test(value)) {
    value = false;
  } else if (/true/i.test(value)) {
    value = true;
  }
  return validate(name, value, schema);
}

export { asBoolean };
