import { Action } from "./Action";

import { IActionSetupParams } from "./types";

class ActionsFactory {
  setupParams: IActionSetupParams;

  constructor(setupParams: IActionSetupParams) {
    this.setupParams = setupParams;
  }

  static get actions(): { [name: string]: typeof Action } {
    throw Error("Implement this");
  }

  make(what: string): Action {
    const cls = this.constructor as typeof ActionsFactory;
    if (!Object.keys(cls.actions).includes(what)) {
      throw new Error(`Unknown action ${what}`);
    }
    return new cls.actions[what](this.setupParams);
  }
}

export { ActionsFactory };
