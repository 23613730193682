import { Rule } from "./Rule";

import {
  IValidationArrayData,
  IValidationSpec,
  ValidationData,
} from "../validator/interfaces";

class MinLengthRule extends Rule {
  static get ruleName(): string {
    return "minlen";
  }

  get params(): string[] {
    return [this.minLen.toString()];
  }

  minLen: number;

  constructor(params: {
    alias: string;
    minLen: number;
    spec: IValidationSpec;
  }) {
    super(params);
    this.minLen = params.minLen;
  }

  static parse(
    alias: string,
    spec: IValidationSpec,
    paramsString: string
  ): Rule {
    return new this({ alias, minLen: Number(paramsString), spec });
  }

  apply = (data: ValidationData): boolean => {
    const value = data as IValidationArrayData | string;
    return value.length >= this.minLen;
  };
}

export { MinLengthRule };
