import { Socket } from "./Socket";

import { ISocketContext } from "./types";

class SocketsFactory {
  context: ISocketContext;

  constructor(context: ISocketContext) {
    this.context = context;
  }

  static get sockets(): { [name: string]: typeof Socket } {
    throw Error("Implement this");
  }

  make(what: string): Socket {
    const cls = this.constructor as typeof SocketsFactory;
    if (!Object.keys(cls.sockets).includes(what)) {
      throw new Error(`Unknown socket ${what}`);
    }
    return new cls.sockets[what](this.context);
  }
}

export { SocketsFactory };
