import io from "socket.io-client";

import { buildUrl } from "./buildUrl";
import { IEventsSchema, ISocketContext, SocketCallbackFunction } from "./types";

class Socket {
  _context: ISocketContext;
  _rooms: string[];
  _callbacks: {
    [event: string]: {
      onSuccess: SocketCallbackFunction;
      onError: SocketCallbackFunction;
    };
  };
  _socket?: SocketIOClient.Socket;

  constructor(context: ISocketContext) {
    this._context = context;
    this._socket = undefined;
    this._rooms = [];
    this._callbacks = {};
    this.connect();
  }

  connect(): void {
    if (!this.connected) {
      const socketOptions: SocketIOClient.ConnectOpts = {
        timeout:
          this._context.timeout !== undefined ? this._context.timeout : 1000,
      };
      if (this._context.maxRetries !== undefined) {
        socketOptions.reconnection = this._context.maxRetries > 0;
        socketOptions.reconnectionAttempts = this._context.maxRetries;
      }
      this._socket = io(
        buildUrl(this._context.hostname, this._context.namespace),
        socketOptions
      );
      if (
        this._context.maxRetries !== undefined &&
        this._context.onFail !== undefined
      ) {
        if (this._context.maxRetries > 0) {
          this._socket.on("reconnect_failed", this._context.onFail);
        } else {
          this._socket.on("connect_error", this._context.onFail);
        }
      }
      if (this._context.onReconnect !== undefined) {
        this._socket.on("reconnect", this._context.onReconnect);
      }
      const cls = this.constructor as typeof Socket;
      for (const event of this.events) {
        this._socket.on(event, (payload: any) => {
          if (this._callbacks[event]) {
            let response;
            const handler = cls._eventsSchema[event];
            try {
              for (const preProcessHook of handler.preProcessHooks) {
                payload = preProcessHook(this, this._context, payload);
              }
              response = handler.responseClass.fromJson(payload);
              for (const postProcessHook of handler.postProcessHooks) {
                response = postProcessHook(this, this._context, response);
              }
            } catch (err) {
              this._callbacks[event].onError(err);
              return;
            }
            this._callbacks[event].onSuccess(response);
          }
        });
      }
      for (const room of this._rooms) {
        this.subscribe(room);
      }
    }
  }

  disconnect(): void {
    if (this._socket !== undefined && this.connected) {
      this._socket.close();
      this._socket = undefined;
    }
  }

  get connected(): boolean {
    return this._socket !== undefined;
  }

  static get _eventsSchema(): IEventsSchema {
    throw new Error("Method not implemented");
  }

  get events() {
    const cls = this.constructor as typeof Socket;
    return Object.keys(cls._eventsSchema);
  }

  subscribe(room: string): void {
    if (this._socket === undefined) {
      throw new Error("Socket is not connected.");
    }
    if (!this._rooms.includes(room)) {
      this._socket.emit("subscribe", room);
      this._rooms = this._rooms.concat([room]);
    }
  }

  unsubscribe(room: string): void {
    if (this._socket === undefined) {
      throw new Error("Socket is not connected.");
    }
    if (this._rooms.includes(room)) {
      this._rooms = this._rooms.filter(el => el !== room);
      this._socket.emit("unsubscribe", room);
    }
  }

  unsubscribeAll(): void {
    if (this._socket === undefined) {
      throw new Error("Socket is not connected.");
    }
    for (const room of this._rooms) {
      this._socket.emit("unsubscribe", room);
    }
    this._rooms = [];
  }

  on(
    event: string,
    onSuccess: SocketCallbackFunction,
    // tslint:disable-next-line:no-empty
    onError: SocketCallbackFunction = () => {}
  ) {
    if (!this.events.includes(event)) {
      throw new Error(`Unknown event "${event}".`);
    }
    if (typeof onSuccess !== "function") {
      throw new Error("Success callback must be a function.");
    }
    if (typeof onError !== "function") {
      throw new Error("Error callback must be a function.");
    }
    this._callbacks[event] = {
      onError,
      onSuccess,
    };
  }

  off(event: string) {
    if (!this.events.includes(event)) {
      throw new Error(`Unknown event "${event}".`);
    }
    if (this._callbacks.hasOwnProperty(event)) {
      delete this._callbacks[event];
    }
  }
}

export { Socket };
