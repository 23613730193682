import { RULE_NAMES } from "../constants/rule_names";

import { Rule } from "./Rule";

import { IValidationSpec, ValidationData } from "../validator/interfaces";

class BetweenRule extends Rule {
  static get ruleName() {
    return RULE_NAMES.BETWEEN;
  }

  get params(): string[] {
    return [this.min.toString(), this.max.toString()];
  }
  min: number;
  max: number;

  constructor(params: {
    alias: string;
    spec: IValidationSpec;
    minimum: number;
    maximum: number;
  }) {
    super(params);
    this.min = params.minimum;
    this.max = params.maximum;
  }

  static parse(
    alias: string,
    spec: IValidationSpec,
    paramsString: string
  ): Rule {
    const [minimum, maximum] = paramsString.split(",");
    return new this({
      alias,
      maximum: Number(maximum),
      minimum: Number(minimum),
      spec,
    });
  }

  apply = (data: ValidationData): boolean => {
    return this.min <= data && data <= this.max;
  };
}

export { BetweenRule };
