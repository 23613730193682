import XRegExp from "xregexp";

import { TYPE_NAMES } from "../constants/type_names";

import { StringType } from "./StringType";

class Base64Type extends StringType {
  static get _supportedRules(): Set<string> {
    return new Set([]);
  }

  static get typeName(): string {
    return TYPE_NAMES.BASE64;
  }

  static check(value: any): boolean {
    if (!super.check(value)) {
      return false;
    }
    const regex = XRegExp(
      `
    ^
    (?:[A-Za-z0-9+/]{4})*
    (?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?
    $
    `,
      "x"
    );
    return regex.test(value);
  }
}

export { Base64Type };
