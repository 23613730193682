import { TYPE_NAMES } from "../constants/type_names";

import { FloatType } from "./FloatType";

class IntegerType extends FloatType {
  static get _supportedRules(): Set<string> {
    return new Set([]);
  }

  static get typeName(): string {
    return TYPE_NAMES.INTEGER;
  }

  static check(value: any): boolean {
    if (!super.check(value)) {
      return false;
    }
    return Number.isInteger(value);
  }
}

export { IntegerType };
