import XRegExp from "xregexp";
import { ValidationData } from "../validator/interfaces";

class Rule {
  get params(): string[] {
    return [];
  }

  /**
   * Specifies the name of the rule
   */
  static get ruleName(): string {
    throw new Error("Not implemented");
  }

  static NAME_PARAM_SEP: string = ":";
  static PARAM_SEP: string = ",";
  static ALIAS_RE: RegExp = XRegExp(
    `
        # beginning of string
        ^
        # group the rule name
        (?P<name>\\w+)
        # if present, detect an alias between square brackets
        (?:\\[
            # group the alias
            (?P<alias>[^\\]]+)
        \\])?
        # end of string
        $
  `,
    "x"
  );
  static RULE_FMT: string = [
    "name[alias]",
    Rule.NAME_PARAM_SEP,
    ["param1", "param2", "param3"].join(Rule.PARAM_SEP),
  ].join("");

  alias: string;

  constructor(params: { alias: string }) {
    this.alias = params.alias;
  }

  /**
   * Creates the rule from a descriptive string
   */
  static parse(alias: string, paramsString: string): Rule {
    return new this({ alias });
  }

  /**
   * Applies the rule to the data
   */
  apply = (data: ValidationData): boolean => {
    throw new Error("Not implemented");
  };
}

export { Rule };
