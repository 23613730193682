import _ from "lodash";

import { EqualsRule } from "../rules/EqualsRule";
import { IsInRule } from "../rules/IsInRule";
import { NullableRule } from "../rules/NullableRule";
import { RequiredRule } from "../rules/RequiredRule";

class Type {
  static get _supportedRules(): Set<string> {
    return new Set([
      EqualsRule.ruleName,
      IsInRule.ruleName,
      NullableRule.ruleName,
      RequiredRule.ruleName,
    ]);
  }

  static get _nullValues(): any[] {
    return [null, undefined];
  }

  static get supportedRules(): Set<string> {
    if (this.prototype instanceof Type) {
      if (!this.hasOwnProperty("_supportedRules")) {
        throw new Error("Please redefine the _supportedRules method");
      }
      const parent = Object.getPrototypeOf(this);
      return new Set([...this._supportedRules, ...parent.supportedRules]);
    } else {
      return this._supportedRules;
    }
  }

  static get nullValues(): any[] {
    if (this.prototype instanceof Type) {
      let nullValues: any[] = [];
      if (this.hasOwnProperty("_nullValues")) {
        nullValues = this._nullValues;
      }
      const parent = Object.getPrototypeOf(this);
      return [...nullValues, ...parent.nullValues];
    } else {
      return this._nullValues;
    }
  }

  /**
   * Defines the name by which the type is identified
   */
  static get typeName(): string {
    throw new Error("Not implemented");
  }

  /**
   * Checks whether the value belongs to the defining type
   */
  static check(value: any): boolean {
    throw new Error("Not implemented");
  }

  /**
   * Checks if the value is null for the current type.
   */
  static isNull(value: any): boolean {
    return this.nullValues.some(el => _.isEqual(el, value));
  }

  static supports(rule: string): boolean {
    return this.supportedRules.has(rule);
  }
}

export { Type };
