import { Rule } from "./Rule";

import { RULE_NAMES } from "../constants/rule_names";
import {
  IValidationArrayData,
  IValidationSpec,
  ValidationData,
} from "../validator/interfaces";

class LenRule extends Rule {
  static get ruleName() {
    return RULE_NAMES.LEN;
  }

  get params(): string[] {
    return [this.len.toString()];
  }
  len: number;

  constructor(params: { alias: string; len: number; spec: IValidationSpec }) {
    super(params);
    this.len = params.len;
  }

  static parse(
    alias: string,
    spec: IValidationSpec,
    paramsString: string
  ): Rule {
    return new this({
      alias,
      len: Number(paramsString),
      spec,
    });
  }

  apply = (data: ValidationData): boolean => {
    const value = data as IValidationArrayData | string;
    return value.length === this.len;
  };
}

export { LenRule };
