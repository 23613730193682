import { IValidationSpec } from "validation-js/lib/validator/interfaces";
import { ValidatorFactory } from "validation-js";

function validate(
  name: string,
  value: any,
  schema: IValidationSpec,
  defaultValue: any = undefined,
): any {
  const validator = ValidatorFactory.make(schema);
  if (value === undefined) {
    value = defaultValue;
  }
  const valid = validator.validate(value);
  if (!valid) {
    throw new Error(`Value ${JSON.stringify(value)} given for variable ${name} is not valid.`);
  }
  return value;
}

export { validate };
