import { Rule } from "./Rule";

import { ValidationData } from "../validator/interfaces";

class MustBeTrueRule extends Rule {
  static get ruleName(): string {
    return "must_be_true";
  }

  apply = (data: ValidationData): boolean => {
    return data === true;
  };
}

export { MustBeTrueRule };
