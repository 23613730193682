import { Rule } from "./Rule";

import { IValidationSpec, ValidationData } from "../validator/interfaces";

class MinRule extends Rule {
  static get ruleName(): string {
    return "min";
  }

  get params(): string[] {
    return [this.min.toString()];
  }
  min: number;

  constructor(params: {
    alias: string;
    minimum: number;
    spec: IValidationSpec;
  }) {
    super(params);
    this.min = params.minimum;
  }

  static parse(
    alias: string,
    spec: IValidationSpec,
    paramsString: string
  ): Rule {
    return new this({ alias, minimum: Number(paramsString), spec });
  }

  apply = (data: ValidationData): boolean => {
    return data >= this.min;
  };
}

export { MinRule };
