import { Rule } from "./Rule";

import { RULE_NAMES } from "../constants/rule_names";

import { ValidationData } from "../validator/interfaces";

class NullableRule extends Rule {
  static get ruleName() {
    return RULE_NAMES.NULLABLE;
  }

  apply = (data: ValidationData): boolean => {
    return true;
  };
}

export { NullableRule };
