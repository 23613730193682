import { TypeFactory } from "../types/TypeFactory";
import { Rule } from "./Rule";

import { RULE_NAMES } from "../constants/rule_names";

import {
  IValidationObjectData,
  IValidationObjectSpec,
  ValidationData,
} from "../validator/interfaces";

class OperationalFieldRule extends Rule {
  static get ruleName() {
    return RULE_NAMES.OPERATIONAL_FIELD;
  }

  get params() {
    return [this.selector, this.description];
  }
  selector: string;
  description: string;
  spec: IValidationObjectSpec;

  constructor(params: {
    alias: string;
    description: string;
    selector: string;
    spec: IValidationObjectSpec;
  }) {
    super(params);
    this.description = params.description;
    this.selector = params.selector;
    this.spec = params.spec;
  }

  static parse(
    alias: string,
    spec: IValidationObjectSpec,
    paramString: string
  ) {
    const [selector, description] = paramString.split(",");
    return new this({ alias, description, selector, spec });
  }

  apply = (data: ValidationData): boolean => {
    const obj = data as IValidationObjectData;
    const type = TypeFactory.get(this.spec.schema[this.description].type);
    if (obj[this.selector] === "other") {
      return !type.isNull(obj[this.description]);
    } else {
      return type.isNull(obj[this.description]);
    }
  };
}

export { OperationalFieldRule };
