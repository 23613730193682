import { Rule } from "./Rule";

import { IValidationSpec, ValidationData } from "../validator/interfaces";

class MaxRule extends Rule {
  static get ruleName(): string {
    return "max";
  }

  max: number;

  constructor(params: {
    alias: string;
    maximum: number;
    spec: IValidationSpec;
  }) {
    super(params);
    this.max = params.maximum;
  }

  static parse(
    alias: string,
    spec: IValidationSpec,
    paramsString: string
  ): Rule {
    return new this({ alias, maximum: Number(paramsString), spec });
  }

  get params(): string[] {
    return [this.max.toString()];
  }

  apply = (data: ValidationData): boolean => {
    return data <= this.max;
  };
}

export { MaxRule };
