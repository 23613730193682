import {
  IBaseValidationOutcomeJson,
  IValidationObjectSpec,
  IValidationSpec,
  ValidationOutcomeJson,
} from "validation-js/lib/validator/interfaces";

import {
  IFormValidationOutcome,
  INestedDictionary,
  IValidationErrorJson,
  IValidationOutcomeJsonStep1,
  IValidationOutcomeJsonStep2alt1,
  IValidationOutcomeJsonStep2alt2,
  NestedDictionary,
  ValidationOutcomeJsonStep2,
  ValidationOutcomeJsonStep3,
} from "./types";

import { IParams } from "../Action/types";

/**
 * Expand value to a nested dictionary made out of the given keys.
 *
 * With input ('abc', ['key1', 'key2', 'key3']) this function returns:
 *
 *     {
 *       key1: {
 *         key2: {
 *           key3: 'abc'
 *         }
 *       }
 *     }
 */
function expand<T>(value: T, keys: string[]): NestedDictionary<T> {
  if (keys.length > 0) {
    const [key, ...rest] = keys;
    return {
      [key]: expand(value, rest),
    };
  } else {
    return value;
  }
}

/**
 * Extract a value from a nested dictionary using the given keys.
 * If a key is missing, returns undefined.
 *
 * With input ({ key1: { key2: { key3: 'abc' } } }, ['key1', 'key2', 'key3']),
 * this function returns 'abc'.
 */
function extract<T>(data: NestedDictionary<T>, keys: string[]): T | undefined {
  if (data === undefined) {
    return undefined;
  } else if (keys.length > 0) {
    const [key, ...rest] = keys;
    const nested = data as INestedDictionary<T>;
    return extract(nested[key], rest);
  } else {
    return data as T;
  }
}

/**
 * Merges two objects, by recursively merging each key of the two.
 *
 * If a key is present in both objects, then it must be of the same type, e.g.,
 * both keys must be a primitive type, or an array type, or an object type.
 *
 * If a key is a primitive type, the second object will take precedence over
 * the first object.
 *
 * If a key is an array, the two keys will be merged.
 */
function merge(
  obj1: NestedDictionary<any>,
  obj2: NestedDictionary<any>
): NestedDictionary<any> {
  const res = { ...obj1 };
  for (const key of Object.keys(obj2)) {
    if (key in res) {
      if (
        typeof obj1[key] !== typeof obj2[key] ||
        Array.isArray(obj1[key]) !== Array.isArray(obj2[key])
      ) {
        throw new Error(`Type of ${key} differs across objects`);
      } else if (Array.isArray(obj1[key])) {
        res[key] = obj1[key].concat(obj2[key]);
      } else if (typeof obj1[key] === typeof {}) {
        res[key] = merge(obj1[key], obj2[key]);
      } else {
        res[key] = obj2[key];
      }
    } else {
      res[key] = obj2[key];
    }
  }
  return res;
}

/**
 * Recursively deletes all elements_errors from a ValidationOutcome.toJson()
 * object.
 */
function removeElementsErrors(
  errors: ValidationOutcomeJson
): IValidationOutcomeJsonStep1 {
  const res: IValidationOutcomeJsonStep1 = {
    errors: errors.errors,
  };
  if ("schema_errors" in errors) {
    res.schema_errors = {};
    for (const key of Object.keys(errors.schema_errors)) {
      res.schema_errors[key] = removeElementsErrors(errors.schema_errors[key]);
    }
  }
  return res;
}

/**
 * Recursively moves object errors down to their schema, e.g., moves an
 * {'equals', ['a', 'b']} error onto the keys 'a', 'b' in the schema_errors.
 */
function moveObjectErrorsDown(
  errors: IValidationOutcomeJsonStep1
): ValidationOutcomeJsonStep2 {
  if (errors.schema_errors !== undefined) {
    const res: IValidationOutcomeJsonStep2alt2 = {
      schema_errors: {},
    };
    for (const error of errors.errors) {
      for (const param of error.params) {
        if (res.schema_errors[param] === undefined) {
          if (errors.schema_errors[param] === undefined) {
            res.schema_errors[param] = {
              errors: [],
            };
          } else {
            res.schema_errors[param] = {
              errors: errors.schema_errors[param].errors,
            };
          }
        }
        const resParamErrors = res.schema_errors[
          param
        ] as IValidationOutcomeJsonStep2alt1;
        resParamErrors.errors = resParamErrors.errors.concat([error]);
      }
    }
    for (const key of Object.keys(errors.schema_errors)) {
      if (!(key in res.schema_errors)) {
        res.schema_errors[key] = moveObjectErrorsDown(
          errors.schema_errors[key]
        );
      }
    }
    return res;
  } else {
    return {
      errors: errors.errors,
    };
  }
}

/**
 * Moves all error arrays up to the key they belong to. This assumes that all
 * object errors have been moved down to the schema_errors.
 */
function moveErrorsUp(
  errors: ValidationOutcomeJsonStep2
): ValidationOutcomeJsonStep3 {
  const res: ValidationOutcomeJsonStep3 = {
    schema_errors: {},
  };
  if ("schema_errors" in errors) {
    for (const key of Object.keys(errors.schema_errors)) {
      if ("schema_errors" in errors.schema_errors[key]) {
        const nested = errors.schema_errors[key] as ValidationOutcomeJsonStep2;
        res.schema_errors[key] = moveErrorsUp(nested);
      } else {
        const nested = errors.schema_errors[key] as {
          errors: IValidationErrorJson[];
        };
        res.schema_errors[key] = nested.errors;
      }
    }
  }
  return res;
}

/**
 * Removes all schema_errors keys from the object. This assumes that all object
 * errors have been removed, and that all elements_errors have been removed too.
 */
function collapseSchemaErrors(
  errors: ValidationOutcomeJsonStep3
): IFormValidationOutcome {
  const res: IFormValidationOutcome = {};
  if ("schema_errors" in errors) {
    for (const key of Object.keys(errors.schema_errors)) {
      if ("schema_errors" in errors.schema_errors[key]) {
        const nested = errors.schema_errors[key] as ValidationOutcomeJsonStep3;
        res[key] = collapseSchemaErrors(nested);
      } else {
        res[key] = errors.schema_errors[key] as IValidationErrorJson[];
      }
    }
  }
  return res;
}

/**
 * Converts a ValidationOutcome to a nested dictionary in which keys represent
 * actual object keys, and all values are array errors.
 */
function simplifyErrors(
  errors: IBaseValidationOutcomeJson
): NestedDictionary<IValidationErrorJson[]> {
  /* ignore all element errors */
  const step1 = removeElementsErrors(errors);
  /* move errors that are next to schema errors inside the schema errors */
  const step2 = moveObjectErrorsDown(step1);
  /* move errors that are not next to schema errors up to their key */
  const step3 = moveErrorsUp(step2);
  /* remove all schema errors */
  return collapseSchemaErrors(step3);
}

/**
 * Checks if the given spec has the required rule.
 */
function isRequired(spec: IValidationSpec): boolean {
  let rules = spec.rules;
  if (typeof rules === "string") {
    rules = rules.split("|");
  }
  return rules.includes("required");
}

/**
 * Creates an empty object given a schema. An empty object contains only the
 * keys of nested objects, and only if they are required.
 */
function emptySchemaObject(spec: IValidationObjectSpec): IParams {
  const res: IParams = {};
  const schema = spec.schema;
  for (const key of Object.keys(schema)) {
    const nestedSpec = schema[key];
    if ("schema" in nestedSpec && isRequired(nestedSpec)) {
      res[key] = emptySchemaObject(nestedSpec);
    }
  }
  return res;
}

export {
  expand,
  extract,
  merge,
  removeElementsErrors,
  collapseSchemaErrors,
  moveObjectErrorsDown,
  moveErrorsUp,
  simplifyErrors,
  emptySchemaObject,
};
