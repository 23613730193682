import { BetweenLengthRule } from "../rules/BetweenLengthRule";
import { LenRule } from "../rules/LenRule";
import { MaxLengthRule } from "../rules/MaxLengthRule";
import { MinLengthRule } from "../rules/MinLengthRule";

import { TYPE_NAMES } from "../constants/type_names";

import { Type } from "./Type";

class SequenceType extends Type {
  static get _supportedRules(): Set<string> {
    return new Set([
      MinLengthRule.ruleName,
      MaxLengthRule.ruleName,
      BetweenLengthRule.ruleName,
      LenRule.ruleName,
    ]);
  }

  static get _nullValues(): any[] {
    return [[]];
  }

  static get typeName(): string {
    return TYPE_NAMES.SEQUENCE;
  }

  static check(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    return typeof value[Symbol.iterator] === "function";
  }
}

export { SequenceType };
