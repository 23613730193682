import { TYPE_NAMES } from "../constants/type_names";

import { StringType } from "./StringType";

class Base58Type extends StringType {
  static get _supportedRules(): Set<string> {
    return new Set([]);
  }

  static get typeName(): string {
    return TYPE_NAMES.BASE58;
  }

  static check(value: any): boolean {
    if (!super.check(value)) {
      return false;
    }
    return /^[1-9A-HJ-NP-Za-km-z]+$/.test(value);
  }
}

export { Base58Type };
