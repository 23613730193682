import { TYPE_NAMES } from "../constants/type_names";

import { SequenceType } from "./SequenceType";

class ArrayType extends SequenceType {
  static get _supportedRules(): Set<string> {
    return new Set([]);
  }

  static get typeName(): string {
    return TYPE_NAMES.ARRAY;
  }

  static check(value: any): boolean {
    if (!super.check(value)) {
      return false;
    }
    return Array.isArray(value);
  }
}

export { ArrayType };
