import { ValidationError } from "./ValidationError";

import { ValidationOutcomeJson } from "./interfaces";

class BaseValidationOutcome {
  errors: ValidationError[] = [];

  merge(outcome: BaseValidationOutcome): void {
    this.addErrors(outcome.errors);
  }

  addError = (error: ValidationError): void => {
    this.errors.push(error);
  };

  addErrors = (errors: ValidationError[]): void => {
    for (const error of errors) {
      this.addError(error);
    }
  };

  toJson(): ValidationOutcomeJson {
    return {
      errors: this.errors.map(el => ({
        name: el.name,
        params: el.params,
      })),
    };
  }

  empty(): void {
    this.errors = [];
  }
}

export { BaseValidationOutcome };
