import { Rule } from "./Rule";

import { IValidationSpec, ValidationData } from "../validator/interfaces";

import { getSize } from "../utils/Base64EncodedFileUtils";

class MinSizeRule extends Rule {
  static get ruleName(): string {
    return "min_size";
  }

  get params(): string[] {
    return [this.minSize.toString()];
  }

  minSize: number;

  constructor(params: {
    alias: string;
    minSize: number;
    spec: IValidationSpec;
  }) {
    super(params);
    this.minSize = params.minSize;
  }

  static parse(
    alias: string,
    spec: IValidationSpec,
    paramsString: string
  ): Rule {
    return new this({ alias, minSize: Number(paramsString), spec });
  }

  apply = (data: ValidationData): boolean => {
    const value = data as string;
    return getSize(value) >= this.minSize;
  };
}

export { MinSizeRule };
