import { Decimal } from "decimal.js";

import { RULE_NAMES } from "../constants/rule_names";

import { Rule } from "./Rule";

import { ValidationData } from "../validator/interfaces";

class DecimalRule extends Rule {
  static get ruleName() {
    return RULE_NAMES.DECIMAL;
  }

  apply = (data: ValidationData): boolean => {
    try {
      const value = data as string | number;
      const _ = new Decimal(value);
      return true;
    } catch (e) {
      return false;
    }
  };
}

export { DecimalRule };
