import { ValidatorFactory } from "validation-js";

import { IValidationObjectSpec } from "validation-js/lib/validator/interfaces";

class SocketObject {
  static get schema(): IValidationObjectSpec {
    throw new Error("Not implemented");
  }

  static fromJson(data: any): SocketObject {
    const validator = ValidatorFactory.make(this.schema);
    const valid = validator.validate(data);
    if (!valid) {
      throw validator.errors;
    }
    const res = new this();
    const objSchema = this.schema.schema;
    for (const key in objSchema) {
      if (key in objSchema) {
        // @ts-ignore
        res[key] = data[key];
      }
    }
    return res;
  }
}

export { SocketObject };
