import { ValidationError } from "./ValidationError";

import { ValidationOutcomeJson } from "./interfaces";

import { BaseValidationOutcome } from "./BaseValidationOutcome";

class ObjectValidationOutcome extends BaseValidationOutcome {
  schemaErrors: {
    [key: string]: BaseValidationOutcome;
  } = {};

  private checkAndAddKey = (
    key: string,
    objectClass: typeof BaseValidationOutcome
  ): void => {
    if (!(key in this.schemaErrors)) {
      this.schemaErrors[key] = new BaseValidationOutcome();
    }
    if (!(this.schemaErrors[key] instanceof objectClass.constructor)) {
      const newOutcome = new objectClass();
      newOutcome.merge(this.schemaErrors[key]);
      this.schemaErrors[key] = newOutcome;
    }
  };

  merge(outcome: BaseValidationOutcome): void {
    super.merge(outcome);
    if (outcome instanceof ObjectValidationOutcome) {
      for (const key of Object.keys(outcome.schemaErrors)) {
        this.addObjectErrors(key, outcome.schemaErrors[key]);
      }
    }
  }

  addObjectError = (key: string, error: ValidationError): void => {
    this.checkAndAddKey(key, BaseValidationOutcome);
    this.schemaErrors[key].addError(error);
  };

  addObjectErrors = (key: string, objectError: BaseValidationOutcome): void => {
    this.checkAndAddKey(key, Object.getPrototypeOf(objectError).constructor);
    this.schemaErrors[key].merge(objectError);
  };

  toJson(): ValidationOutcomeJson {
    return {
      ...super.toJson(),
      schema_errors: Object.keys(this.schemaErrors).reduce(
        (acc, key) => {
          // @ts-ignore
          acc[key] = this.schemaErrors[key].toJson();
          return acc;
        },
        {} as ValidationOutcomeJson
      ),
    };
  }

  empty(): void {
    super.empty();
    this.schemaErrors = {};
  }
}

export { ObjectValidationOutcome };
