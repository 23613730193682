import {
  asBase58,
  asBase64,
  asBoolean,
  asEmail,
  asFloat,
  asInteger,
  asISO8601Date,
  asString,
  asUrl,
  asUuid
} from "./types";

function getFromContainer(container: any, name?: string, default_value: any = undefined): any {
  if (name === null || name === undefined) {
    return container;
  }

  let value = default_value;
  if (name in container) {
    value = container[name];
  }

  return {
    asBase58: (rules: string | string[] = []) => asBase58(name, value, rules),
    asBase64: (rules: string | string[] = []) => asBase64(name, value, rules),
    asBoolean: (rules: string | string[] = []) => asBoolean(name, value, rules),
    asEmail: (rules: string | string[] = []) => asEmail(name, value, rules),
    asFloat: (rules: string | string[] = []) => asFloat(name, value, rules),
    asInteger: (rules: string | string[] = []) => asInteger(name, value, rules),
    asISO8601Date: (rules: string | string[] = []) => asISO8601Date(name, value, rules),
    asString: (rules: string | string[] = []) => asString(name, value, rules),
    asUrl: (rules: string | string[] = []) => asUrl(name, value, rules),
    asUuid: (rules: string | string[] = []) => asUuid(name, value, rules),
  };
}

export { getFromContainer };