import { Rule } from "./Rule";

import { RULE_NAMES } from "../constants/rule_names";

import { ValidationData } from "../validator/interfaces";

class RequiredRule extends Rule {
  static get ruleName() {
    return RULE_NAMES.REQUIRED;
  }

  apply = (data: ValidationData): boolean => {
    return data !== undefined;
  };
}

export { RequiredRule };
