import axios from "axios";

import pathToRegexp from "path-to-regexp";

import https from "https";

import { ValidatorFactory } from "validation-js";
import { IValidationObjectSpec } from "validation-js/lib/validator/interfaces";

import {
  ActionRequest,
  ActionResponse,
  FailureHook,
  HttpVerb,
  IActionContext,
  IActionSetupParams,
  IParams,
  IStatusExceptions,
  PreSendHook,
  SuccessHook,
} from "./types";

import { ActionObject } from "./ActionObject";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

class Action {
  static requestClass: typeof ActionObject | undefined;
  static responseClass: typeof ActionObject | undefined;

  requestBody: ActionObject | undefined;

  _cache: any;
  _context: IActionContext;

  _routeParams: IParams = {};
  _queryParams: IParams = {};

  preSendHooks: PreSendHook[] = [];
  successHooks: SuccessHook[] = [];
  failureHooks: FailureHook[] = [];

  statusExceptions: IStatusExceptions = {};

  constructor(setupParams: IActionSetupParams) {
    this._cache = setupParams.cache;
    this._context = setupParams.context;

    if (setupParams.routeParams) {
      this._routeParams = setupParams.routeParams;
    }

    if (setupParams.queryParams) {
      this._queryParams = setupParams.queryParams;
    }

    if (setupParams.preSendHooks) {
      this.preSendHooks = setupParams.preSendHooks;
    }

    if (setupParams.successHooks) {
      this.successHooks = setupParams.successHooks;
    }

    if (setupParams.failureHooks) {
      this.failureHooks = setupParams.failureHooks;
    }

    if (setupParams.statusExceptions) {
      this.statusExceptions = setupParams.statusExceptions;
    }
  }

  static get verb(): HttpVerb {
    throw new Error("Not implemented");
  }

  static get route(): string {
    throw new Error("Not implemented");
  }

  static get routeSchema(): IValidationObjectSpec {
    return {
      rules: ["nullable"],
      schema: {},
      type: "object",
    };
  }

  static get querySchema(): IValidationObjectSpec {
    return {
      rules: ["nullable"],
      schema: {},
      type: "object",
    };
  }

  private static get _preSendHooks(): PreSendHook[] {
    return [];
  }

  private static get _successHooks(): SuccessHook[] {
    return [];
  }

  private static get _failureHooks(): FailureHook[] {
    return [];
  }

  private static get _statusExceptions(): IStatusExceptions {
    return {};
  }

  /* Url & query params */

  private buildUrl(): string {
    const cls = this.constructor as typeof Action;
    const rawRoute = cls.route.replace(
      /{([^}]*)}/g,
      (_, capture) => `:${capture}`
    );
    const validator = ValidatorFactory.make(cls.routeSchema);
    const valid = validator.validate(this._routeParams);
    if (!valid) {
      throw validator.errors;
    }
    return pathToRegexp.compile(rawRoute)(this._routeParams);
  }

  private buildQuery(): any {
    const cls = this.constructor as typeof Action;
    const validator = ValidatorFactory.make(cls.querySchema);
    const valid = validator.validate(this._queryParams);
    if (!valid) {
      throw validator.errors;
    }
    return this._queryParams;
  }

  private retrieveFromAncestors(varName: string): any[] {
    const cls = this.constructor as typeof Action;
    const parent = Object.getPrototypeOf(cls).prototype;
    let result: any[] = [];
    if (parent instanceof Action || parent === Action.prototype) {
      result = result.concat(parent.retrieveFromAncestors(varName));
    }
    if (cls.hasOwnProperty(varName)) {
      // @ts-ignore
      result = result.concat(cls[varName]);
    }
    return result;
  }

  /* Status Exceptions */

  getException(
    request: ActionRequest,
    response: ActionResponse
  ): Error | undefined {
    const exceptions = this.getAllStatusExceptions();
    if (response.status in exceptions) {
      return new exceptions[response.status]();
    }
  }

  getAllStatusExceptions(): IStatusExceptions {
    let exceptions: IStatusExceptions[] = this.retrieveFromAncestors(
      "_statusExceptions"
    );
    if (this.statusExceptions) {
      exceptions = exceptions.concat(this.statusExceptions);
    }
    return exceptions.reduce((acc, val) => {
      return Object.assign({}, acc, val);
    }, {});
  }

  /* Pre send hooks */

  getAllPreSendHooks(): PreSendHook[] {
    let preSendHooks: PreSendHook[] = this.retrieveFromAncestors(
      "_preSendHooks"
    );
    if (this.preSendHooks) {
      preSendHooks = preSendHooks.concat(this.preSendHooks);
    }
    return preSendHooks;
  }

  /* Success hooks */

  getAllSuccessHooks(): SuccessHook[] {
    let successHooks: SuccessHook[] = this.retrieveFromAncestors(
      "_successHooks"
    );
    if (this.successHooks) {
      successHooks = successHooks.concat(this.successHooks);
    }
    return successHooks;
  }

  /* Failure hooks */

  getAllFailureHooks(): FailureHook[] {
    let failureHooks: FailureHook[] = this.retrieveFromAncestors(
      "_failureHooks"
    );
    if (this.failureHooks) {
      failureHooks = failureHooks.concat(this.failureHooks);
    }
    return failureHooks;
  }

  /* Route & query params */

  set routeParams(routeParams: IParams) {
    this._routeParams = routeParams;
  }

  set queryParams(queryParams: IParams) {
    this._queryParams = queryParams;
  }

  /* Request method */

  private buildRequest(): ActionRequest {
    const cls = this.constructor as typeof Action;
    const request: ActionRequest = {};
    request.method = cls.verb;
    request.baseURL = this._context.hostname;
    request.url = this.buildUrl();
    request.params = this.buildQuery();
    if (this._context.skipHTTPSvalidation) {
      request.httpsAgent = new https.Agent({ rejectUnauthorized: false });
    }
    if (this._context.withCredentials) {
      request.withCredentials = true;
    }
    if (cls.requestClass) {
      if (!this.requestBody) {
        throw new Error("Specify a request body object");
      }
      request.data = this.requestBody.toJson();
    }
    return request;
  }

  protected async getResponse(): Promise<ActionResponse> {
    let request = this.buildRequest();
    for (const preSendHook of this.getAllPreSendHooks()) {
      request = await preSendHook(this, this._cache, this._context, request);
    }
    let response: ActionResponse;
    try {
      response = await axios(request);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      let errorResponse: ActionResponse = err.response;
      for (const failureHook of this.getAllFailureHooks()) {
        errorResponse = await failureHook(
          this,
          this._cache,
          this._context,
          errorResponse
        );
      }
      if (errorResponse.status >= 200 && errorResponse.status < 300) {
        response = errorResponse;
      } else {
        const ex = this.getException(request, errorResponse);
        if (ex === undefined) {
          throw new Error(
            `${this.constructor.name} failed with status ${
              errorResponse.status
            }`
          );
        } else {
          throw ex;
        }
      }
    }
    for (const successHook of this.getAllSuccessHooks()) {
      response = await successHook(this, this._cache, this._context, response);
    }
    return response;
  }

  async run(): Promise<any> {
    const response: ActionResponse = await this.getResponse();
    const cls = this.constructor as typeof Action;
    if (cls.responseClass) {
      return cls.responseClass.fromJson(response.data);
    }
  }
}

export { Action };
