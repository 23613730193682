import { stripUndefinedFields } from "../utils/undefinedFields";
import { BaseValidator } from "./BaseValidator";

import {
  IValidationObjectData,
  IValidationObjectSpec,
  IValidationSpec,
  ValidationData,
} from "./interfaces";

import { ValidatorFactory } from "./ValidatorFactory";

import { ValidationError } from "./ValidationError";

import { ObjectValidationOutcome } from "./ObjectValidationOutcome";

import { ObjectType } from "../types/ObjectType";

import { RequiredRule } from "../rules/RequiredRule";

class ObjectValidator extends BaseValidator {
  errors: ObjectValidationOutcome;

  constructor(spec: IValidationSpec) {
    super(spec);
    this.errors = new ObjectValidationOutcome();
  }

  isRequired(key: string): boolean {
    const cls = this.constructor as typeof BaseValidator;
    const spec = this.spec as IValidationObjectSpec;
    const rules = cls.splitRules(spec.schema[key].rules);
    return rules.includes(RequiredRule.ruleName);
  }

  validate(data: ValidationData, strict: boolean = false): boolean {
    let success = super.validate(data, strict);

    data = stripUndefinedFields(data);

    if (!ObjectType.check(data)) {
      return success;
    }

    const spec = this.spec as IValidationObjectSpec;

    const dataObj = data as IValidationObjectData;

    for (const key in spec.schema) {
      if (key in spec.schema) {
        if (dataObj[key] === undefined) {
          if (this.isRequired(key)) {
            this.errors.addObjectError(
              key,
              new ValidationError(RequiredRule.ruleName, [])
            );
            success = false;
          }
        } else {
          const validator = ValidatorFactory.make(spec.schema[key]);

          const valid = validator.validate(dataObj[key], strict);

          if (!valid) {
            success = false;
            this.errors.addObjectErrors(key, validator.errors);
          }
        }
      }
    }

    if (strict) {
      for (const key in dataObj) {
        if (key in dataObj) {
          if (!Object.keys(spec.schema).includes(key)) {
            success = false;
            this.errors.addObjectError(key, new ValidationError("strict", []));
          }
        }
      }
    }

    return success;
  }
}

export { ObjectValidator };
