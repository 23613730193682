const RULE_NAMES = {
  ALPHA: "alpha",
  ALPHA_DASH: "alphadash",
  ALPHA_NUM: "alphanum",
  BETWEEN: "between",
  BETWEEN_LENGTH: "betweenlen",
  DECIMAL: "decimal",
  EQUALS: "equals",
  EQUALS_TO: "equals_to",
  HEX: "hex",
  IS_IN: "in",
  LEN: "len",
  MAX: "max",
  MAX_LENGTH: "maxlen",
  MIN: "min",
  MIN_LENGTH: "minlen",
  NULLABLE: "nullable",
  OPERATIONAL_FIELD: "operational_field_tmp_rule",
  REGEX: "regex",
  REQUIRED: "required",
};

export { RULE_NAMES };
