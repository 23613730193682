import { Rule } from "./Rule";

import { RULE_NAMES } from "../constants/rule_names";

import {
  IValidationObjectData,
  IValidationSpec,
  ValidationData,
} from "../validator/interfaces";

class EqualsToRule extends Rule {
  static get ruleName() {
    return RULE_NAMES.EQUALS_TO;
  }

  get params() {
    return [this.field1, this.field2];
  }
  field1: string;
  field2: string;

  constructor(params: {
    alias: string;
    field1: string;
    field2: string;
    spec: IValidationSpec;
  }) {
    super(params);
    this.field1 = params.field1;
    this.field2 = params.field2;
  }

  static parse(alias: string, spec: IValidationSpec, paramString: string) {
    const [field1, field2] = paramString.split(",");
    return new this({ alias, field1, field2, spec });
  }

  apply = (data: ValidationData): boolean => {
    const obj = data as IValidationObjectData;
    return obj[this.field1] === obj[this.field2];
  };
}

export { EqualsToRule };
