import { Rule } from "./Rule";

import {
  IValidationArrayData,
  IValidationSpec,
  ValidationData,
} from "../validator/interfaces";

class MaxLengthRule extends Rule {
  static get ruleName(): string {
    return "maxlen";
  }

  get params(): string[] {
    return [this.maxLen.toString()];
  }
  maxLen: number;

  constructor(params: {
    alias: string;
    maxLen: number;
    spec: IValidationSpec;
  }) {
    super(params);
    this.maxLen = params.maxLen;
  }

  static parse(
    alias: string,
    spec: IValidationSpec,
    paramsString: string
  ): Rule {
    return new this({ alias, maxLen: Number(paramsString), spec });
  }

  apply = (data: ValidationData): boolean => {
    const value = data as IValidationArrayData | string;
    return value.length <= this.maxLen;
  };
}

export { MaxLengthRule };
