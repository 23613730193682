import { validate } from "./validate";

function asString(
  name: string,
  value: any,
  rules: string | string[] = []
): string {
  const schema = {
    type: "string",
    rules: rules,
  };
  return validate(name, value, schema, "");
}

export { asString };
