import XRegExp from "xregexp";
import { IValidationSpec } from "../validator/interfaces";

import { Rule } from "./Rule";

import { AlphaDashRule } from "./AlphaDashRule";
import { AlphaNumRule } from "./AlphaNumRule";
import { AlphaRule } from "./AlphaRule";
import { BetweenLengthRule } from "./BetweenLengthRule";
import { BetweenRule } from "./BetweenRule";
import { DecimalRule } from "./DecimalRule";
import { EqualsRule } from "./EqualsRule";
import { EqualsToRule } from "./EqualsToRule";
import { FileFormatRule } from "./FileFormatRule";
import { FileTypeRule } from "./FileTypeRule";
import { HexRule } from "./HexRule";
import { IsInRule } from "./IsInRule";
import { LenRule } from "./LenRule";
import { MaxLengthRule } from "./MaxLengthRule";
import { MaxRule } from "./MaxRule";
import { MaxSizeRule } from "./MaxSizeRule";
import { MinLengthRule } from "./MinLengthRule";
import { MinRule } from "./MinRule";
import { MinSizeRule } from "./MinSizeRule";
import { MustBeTrueRule } from "./MustBeTrueRule";
import { NullableIfRule } from "./NullableIfRule";
import { NullableRule } from "./NullableRule";
import { OperationalFieldRule } from "./OperationalFieldRule";
import { RegexRule } from "./RegexRule";
import { RequiredRule } from "./RequiredRule";

class RuleFactory {
  static rules = {
    [AlphaRule.ruleName]: AlphaRule,
    [AlphaNumRule.ruleName]: AlphaNumRule,
    [AlphaDashRule.ruleName]: AlphaDashRule,
    [BetweenRule.ruleName]: BetweenRule,
    [BetweenLengthRule.ruleName]: BetweenLengthRule,
    [DecimalRule.ruleName]: DecimalRule,
    [EqualsRule.ruleName]: EqualsRule,
    [EqualsToRule.ruleName]: EqualsToRule,
    [FileFormatRule.ruleName]: FileFormatRule,
    [FileTypeRule.ruleName]: FileTypeRule,
    [HexRule.ruleName]: HexRule,
    [IsInRule.ruleName]: IsInRule,
    [LenRule.ruleName]: LenRule,
    [MaxLengthRule.ruleName]: MaxLengthRule,
    [MaxRule.ruleName]: MaxRule,
    [MaxSizeRule.ruleName]: MaxSizeRule,
    [MinLengthRule.ruleName]: MinLengthRule,
    [MinRule.ruleName]: MinRule,
    [MinSizeRule.ruleName]: MinSizeRule,
    [MustBeTrueRule.ruleName]: MustBeTrueRule,
    [NullableIfRule.ruleName]: NullableIfRule,
    [NullableRule.ruleName]: NullableRule,
    [OperationalFieldRule.ruleName]: OperationalFieldRule,
    [RegexRule.ruleName]: RegexRule,
    [RequiredRule.ruleName]: RequiredRule,
  };

  static create(desc: string, spec: IValidationSpec) {
    const [namePattern, ...params] = desc.split(Rule.NAME_PARAM_SEP);

    let alias;

    const match = XRegExp.exec(namePattern, Rule.ALIAS_RE);

    let name;

    if (match !== null) {
      // @ts-ignore
      name = match.name;
      // @ts-ignore
      alias = match.alias || name;
    } else {
      throw new Error(`Rule ${desc} has wrong format.
      Rules must have the following format: ${Rule.RULE_FMT}`);
    }

    if (name in this.rules) {
      return this.rules[name].parse(
        alias,
        spec,
        params.join(Rule.NAME_PARAM_SEP)
      );
    } else {
      throw new Error(`No rule found with name ${name}`);
    }
  }
}

export { RuleFactory };
