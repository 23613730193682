import { Rule } from "./Rule";

import { IValidationSpec, ValidationData } from "../validator/interfaces";

class RegexRule extends Rule {
  static get ruleName(): string {
    return "regex";
  }

  get params() {
    return [this.regex.source];
  }
  regex: RegExp;

  constructor(params: { alias: string; regex: string; spec: IValidationSpec }) {
    super(params);
    this.regex = new RegExp(params.regex);
  }

  static parse(
    alias: string,
    spec: IValidationSpec,
    paramsString: string
  ): Rule {
    return new this({ alias, regex: paramsString, spec });
  }

  apply = (data: ValidationData): boolean => {
    const value = data as string;
    return this.regex.test(value);
  };
}

export { RegexRule };
