import { RULE_NAMES } from "../constants/rule_names";

import { Rule } from "./Rule";

import { IValidationSpec, ValidationData } from "../validator/interfaces";

class IsInRule extends Rule {
  static get ruleName(): string {
    return RULE_NAMES.IS_IN;
  }

  get params(): string[] {
    return [this.collection.join(",")];
  }
  collection: string[];

  constructor(params: {
    alias: string;
    collection: string[];
    spec: IValidationSpec;
  }) {
    super(params);
    this.collection = params.collection;
  }

  static parse(
    alias: string,
    spec: IValidationSpec,
    paramsString: string
  ): Rule {
    return new this({ alias, collection: paramsString.split(","), spec });
  }

  apply = (data: ValidationData): boolean => {
    const value = data.toString();
    return this.collection.includes(value);
  };
}

export { IsInRule };
