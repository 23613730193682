import moment from "moment";

import { StringType } from "./StringType";

import { TYPE_NAMES } from "../constants/type_names";

class ISO8601DateType extends StringType {
  static get _supportedRules() {
    return new Set([]);
  }

  static get typeName() {
    return TYPE_NAMES.ISO_8601_DATE;
  }

  static check(value: any) {
    if (!super.check(value)) {
      return false;
    }
    try {
      const m = moment(value, moment.ISO_8601);
      return m.isValid();
    } catch (e) {
      return false;
    }
  }
}

export { ISO8601DateType };
