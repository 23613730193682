import { Form } from "./Form";

import { ActionsFactory } from "../Action/ActionsFactory";

class FormsFactory {
  actionsFactory: ActionsFactory;

  constructor(actionsFactory: ActionsFactory) {
    this.actionsFactory = actionsFactory;
  }

  static get forms(): { [name: string]: typeof Form } {
    throw Error("Implement this");
  }

  make(what: string): Form {
    const cls = this.constructor as typeof FormsFactory;
    if (!Object.keys(cls.forms).includes(what)) {
      throw new Error(`Unknown form ${what}`);
    }
    return new cls.forms[what](this.actionsFactory);
  }
}

export { FormsFactory };
