import XRegExp from "xregexp";

import { TYPE_NAMES } from "../constants/type_names";

import { StringType } from "./StringType";

class UrlType extends StringType {
  static get _supportedRules(): Set<string> {
    return new Set([]);
  }

  static get typeName(): string {
    return TYPE_NAMES.URL;
  }

  static check(value: any): boolean {
    if (!super.check(value)) {
      return false;
    }
    const regex = XRegExp(
      `
    ^
    # http:// or https:// or ftp:// or ftps://
    (?:http|ftp)s?://
    # domain...
    (?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\\.)+
    (?:[A-Z]{2,6}\\.?|[A-Z0-9-]{2,}\\.?)
    |
    # ...or ipv4
    \\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}
    |
    # ...or ipv6
    \\[?[A-F0-9]*:[A-F0-9:]+\\]?)
    # optional port
    (?::\\d+)?
    (?:/?|[/?]\\S+)
    $
    `,
      "xi"
    );
    return regex.test(value);
  }
}

export { UrlType };
