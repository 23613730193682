import { TYPE_NAMES } from "../constants/type_names";
import { NullableIfRule } from "../rules/NullableIfRule";

import { Type } from "./Type";

import { EqualsToRule } from "../rules/EqualsToRule";
import { OperationalFieldRule } from "../rules/OperationalFieldRule";

class ObjectType extends Type {
  static get _supportedRules(): Set<string> {
    return new Set([
      EqualsToRule.ruleName,
      NullableIfRule.ruleName,
      OperationalFieldRule.ruleName,
    ]);
  }

  static get _nullValues(): any[] {
    return [{}];
  }

  static get typeName(): string {
    return TYPE_NAMES.OBJECT;
  }

  static check(value: any): boolean {
    return (
      value !== null && typeof value === typeof {} && !Array.isArray(value)
    );
  }
}

export { ObjectType };
