import { RULE_NAMES } from "../constants/rule_names";

import { Rule } from "./Rule";

import {
  IValidationArrayData,
  IValidationSpec,
  ValidationData,
} from "../validator/interfaces";

class BetweenLengthRule extends Rule {
  static get ruleName() {
    return RULE_NAMES.BETWEEN_LENGTH;
  }

  get params(): string[] {
    return [this.minLen.toString(), this.maxLen.toString()];
  }
  minLen: number;
  maxLen: number;

  constructor(params: {
    alias: string;
    spec: IValidationSpec;
    minLen: number;
    maxLen: number;
  }) {
    super(params);
    this.minLen = params.minLen;
    this.maxLen = params.maxLen;
  }

  static parse(
    alias: string,
    spec: IValidationSpec,
    paramsString: string
  ): Rule {
    const [minLen, maxLen] = paramsString.split(",");
    return new this({
      alias,
      maxLen: Number(maxLen),
      minLen: Number(minLen),
      spec,
    });
  }

  apply = (data: ValidationData): boolean => {
    const value = data as IValidationArrayData | string;
    return this.minLen <= value.length && value.length <= this.maxLen;
  };
}

export { BetweenLengthRule };
