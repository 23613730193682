import { validate } from "./validate";

function asFloat(
  name: string,
  value: any,
  rules: string | string[] = []
): number {
  const schema = {
    type: "float",
    rules: rules,
  };
  if (Number.isNaN(value)) {
    throw new Error(`Value given for variable ${name} is not valid.`);
  }
  if (value !== undefined) {
    value = Number(value);
  }
  return validate(name, value, schema);
}

export { asFloat };
