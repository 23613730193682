import XRegExp from "xregexp";

import { parsePhoneNumberFromString } from "libphonenumber-js";

import { TYPE_NAMES } from "../constants/type_names";

import { StringType } from "./StringType";

class PhoneType extends StringType {
  static get _supportedRules(): Set<string> {
    return new Set([]);
  }

  static get typeName(): string {
    return TYPE_NAMES.PHONE;
  }

  static check(value: any): boolean {
    if (!super.check(value)) {
      return false;
    }
    const regex = XRegExp("^\\s*\\+(\\s*\\(?\\d\\)?-?)*\\s*$", "x");
    if (!regex.test(value)) {
      return false;
    }
    const phone = parsePhoneNumberFromString(value);
    return phone ? phone.isValid() : false;
  }
}

export { PhoneType };
