import { RuleFactory, ValidatorFactory } from "validation-js";

import { BaseValidationOutcome } from "validation-js/lib/validator/BaseValidationOutcome";
import { ObjectValidationOutcome } from "validation-js/lib/validator/ObjectValidationOutcome";

import {
  emptySchemaObject,
  expand,
  extract,
  merge,
  simplifyErrors,
} from "./utils";

import { Action, ActionsFactory } from "..";

import {
  IActionData,
  IFormData,
  IFormMapping,
  IFormValidationOutcome,
  IFormValidationResult,
  IRuleDictionary,
  IValidationErrorJson,
  NestedDictionary,
} from "./types";

import {
  FailureHook,
  IParams,
  PreSendHook,
  SuccessHook,
} from "../Action/types";

import {
  IBaseValidationOutcomeJson,
  IValidationObjectSpec,
  IValidationSpec,
} from "validation-js/lib/validator/interfaces";

class Form {
  static get action(): typeof Action {
    throw Error("Not implemented");
  }

  static get actionName(): string {
    throw Error("Not implemented");
  }

  static get mapping(): IFormMapping {
    throw Error("Not implemented");
  }

  static get preSendHooks(): PreSendHook[] {
    return [];
  }

  static get successHooks(): SuccessHook[] {
    return [];
  }

  static get failureHooks(): FailureHook[] {
    return [];
  }

  _factory: ActionsFactory;
  _validatedKeys: Set<string>;
  ids: {
    [id: string]: string;
  };
  mergeKeys: string[];

  constructor(factory: ActionsFactory) {
    this._factory = factory;
    this._validatedKeys = new Set([]);
    this.ids = {};
    this.mergeKeys = [];
  }

  /**
   * Helper method that returns all fields in data that are associated with a
   * given type in the mapping.
   */
  static getDataByType(data: IFormData, type: string): IFormData {
    return Object.keys(data).reduce(
      (acc, key) => {
        if (this.mapping[key].type === type) {
          acc[key] = data[key];
        }
        return acc;
      },
      {} as IFormData
    );
  }

  static flatToQueryObject(data: IFormData): IParams {
    const res = emptySchemaObject(this.action.routeSchema);
    return merge(res, this.flatToObject(this.getDataByType(data, "query")));
  }

  static flatToRequestObject(data: IFormData): IParams {
    if (this.action.requestClass === undefined) {
      throw new Error(`Action ${this.actionName} is missing a request class`);
    }
    const res = emptySchemaObject(this.action.requestClass.schema);
    return merge(res, this.flatToObject(this.getDataByType(data, "form")));
  }

  static flatToRouteObject(data: IFormData): IParams {
    const res = emptySchemaObject(this.action.routeSchema);
    return merge(res, this.flatToObject(this.getDataByType(data, "uri")));
  }

  /**
   * Helper method that converts some Flattened form data to SDK data.
   */
  static flatToObject(data: IFormData): IParams {
    let res = {};
    for (const key of Object.keys(data)) {
      res = merge(res, expand(data[key], this.mapping[key].keys));
    }
    return res;
  }

  /**
   * Helper method that converts some SDK data to Flattened form data.
   */
  static objectToFlat(data: NestedDictionary<any>): { [key: string]: any } {
    const res = {} as IFormData;
    for (const key of Object.keys(this.mapping)) {
      const value = extract(data, this.mapping[key].keys);
      if (value !== undefined) {
        res[key] = value;
      }
    }
    return res;
  }

  /**
   * Converts an SDK error to flattened Form data.
   */
  mapErrorsFromAction(
    errors: IBaseValidationOutcomeJson
  ): IFormValidationOutcome {
    const cls = this.constructor as typeof Form;
    const res = cls.objectToFlat(simplifyErrors(errors));
    for (const mergeKey of this.mergeKeys) {
      let keyErrors: IValidationErrorJson[] = [];
      for (const key in res) {
        if (key in res) {
          if (key.startsWith(mergeKey)) {
            keyErrors = keyErrors.concat(
              res[key].map((error: IValidationErrorJson) => ({
                name: `mergeParam$${error.name}`,
                params: [key.replace(`${mergeKey}$`, "")].concat(error.params),
              }))
            );
            delete res[key];
          }
        }
      }
      res[mergeKey] = keyErrors;
    }
    return res;
  }

  /**
   * Converts the flattened Form data to SDK data.
   */
  mapDataToAction(data: IFormData): IActionData {
    const toMap = { ...data };
    for (const mergeKey of this.mergeKeys) {
      if (mergeKey in data) {
        const mergeData = data[mergeKey] as { [key: string]: string };
        for (const key in mergeData) {
          if (key in mergeData) {
            const fullKey = `${mergeKey}$${key}`;
            toMap[fullKey] = mergeData[key];
          }
        }
        delete toMap[mergeKey];
      }
    }
    const cls = this.constructor as typeof Form;
    for (const key of Object.keys(toMap)) {
      if (!(key in cls.mapping)) {
        throw Error(`Unknown key ${key}`);
      }
    }
    return {
      queryParams: cls.flatToQueryObject(toMap),
      requestBody: cls.flatToRequestObject(toMap),
      routeParams: cls.flatToRouteObject(toMap),
    };
  }

  /**
   * Validates the Form data.
   */
  validate(data: IFormData): IFormValidationResult {
    const cls = this.constructor as typeof Form;
    const { requestBody, routeParams, queryParams } = this.mapDataToAction(
      data
    );
    // validate request
    if (cls.action.requestClass === undefined) {
      throw new Error(`Action ${cls.actionName} is missing a request class`);
    }
    const {
      valid: requestValid,
      errors: requestErrors,
    } = cls.action.requestClass.validate(requestBody);
    // validate url
    const urlValidator = ValidatorFactory.make(cls.action.routeSchema);
    const urlValid = urlValidator.validate(routeParams);
    const urlErrors = urlValidator.errors;
    // validate query
    const queryValidator = ValidatorFactory.make(cls.action.querySchema);
    const queryValid = queryValidator.validate(queryParams);
    const queryErrors = queryValidator.errors;
    // merge errors
    const errors = new ObjectValidationOutcome();
    // @ts-ignore
    errors.merge(requestErrors);
    // @ts-ignore
    errors.merge(urlErrors);
    // @ts-ignore
    errors.merge(queryErrors);
    // return result
    return {
      errors: this.mapErrorsFromAction(errors.toJson()),
      valid: requestValid && urlValid && queryValid,
    };
  }

  /**
   * Partially validate form data
   */
  validatePartial(key: string, data: IFormData): IFormValidationResult {
    this._validatedKeys.add(key);
    const { valid, errors } = this.validate(data);
    return {
      errors: Array.from(this._validatedKeys).reduce(
        (acc, vKey) => {
          acc[vKey] = errors[vKey] || [];
          return acc;
        },
        {} as IFormValidationOutcome
      ),
      valid,
    };
  }

  /**
   * Return a dictionary of rules for a given form key.
   */
  getRules(key: string): IRuleDictionary {
    const cls = this.constructor as typeof Form;
    const { type, keys } = cls.mapping[key];
    let schema: IValidationObjectSpec;
    if (type === "form") {
      if (cls.action.requestClass === undefined) {
        return {};
      }
      schema = cls.action.requestClass.schema;
    } else if (type === "url") {
      schema = cls.action.routeSchema;
    } else if (type === "query") {
      schema = cls.action.querySchema;
    } else {
      return {};
    }
    for (const subKey of keys) {
      schema = schema.schema[subKey] as IValidationObjectSpec;
    }
    let rules = schema.rules;
    if (typeof rules === "string") {
      rules = rules.split(",");
    }
    return rules.reduce(
      (acc, ruleString) => {
        const rule = RuleFactory.create(ruleString, {} as IValidationSpec);
        acc[rule.alias] = rule.params;
        return acc;
      },
      {} as IRuleDictionary
    );
  }

  /**
   * Sends data through the action associated with the Form.
   */
  async send(data: IFormData): Promise<any> {
    const cls = this.constructor as typeof Form;
    if (cls.action.requestClass === undefined) {
      throw new Error(`Action ${cls.actionName} is missing a request class`);
    }
    const { valid, errors } = this.validate(data);
    if (!valid) {
      throw errors;
    }
    const action = this._factory.make(cls.actionName);
    const { requestBody, routeParams, queryParams } = this.mapDataToAction(
      data
    );
    action.requestBody = cls.action.requestClass.fromJson(requestBody);
    action.routeParams = routeParams;
    action.queryParams = queryParams;
    action.preSendHooks = action.preSendHooks.concat(cls.preSendHooks);
    action.successHooks = action.successHooks.concat(cls.successHooks);
    action.failureHooks = action.failureHooks.concat(cls.failureHooks);
    try {
      return await action.run();
    } catch (err) {
      if (err instanceof BaseValidationOutcome) {
        throw this.mapErrorsFromAction(err.toJson());
      } else {
        throw err;
      }
    }
  }
}

export { Form };
