import { BooleanType } from "../types/BooleanType";
import { TypeFactory } from "../types/TypeFactory";
import { Rule } from "./Rule";

import { RULE_NAMES } from "../constants/rule_names";

import {
  IValidationObjectData,
  IValidationObjectSpec,
  ValidationData,
} from "../validator/interfaces";

class NullableIfRule extends Rule {
  static get ruleName() {
    return RULE_NAMES.NULLABLE_IF;
  }

  get params() {
    return [this.checkField, this.nullableField];
  }
  checkField: string;
  nullableField: string;
  spec: IValidationObjectSpec;

  constructor(params: {
    alias: string;
    checkField: string;
    nullableField: string;
    spec: IValidationObjectSpec;
  }) {
    super(params);
    this.spec = params.spec;
    this.checkField = params.checkField;
    this.nullableField = params.nullableField;
  }

  static parse(
    alias: string,
    spec: IValidationObjectSpec,
    paramString: string
  ) {
    const [checkField, nullableField] = paramString.split(",");
    return new this({ alias, checkField, nullableField, spec });
  }

  apply = (data: ValidationData): boolean => {
    const obj = data as IValidationObjectData;
    const type = TypeFactory.get(this.spec.schema[this.nullableField].type);
    if (obj[this.checkField]) {
      return type.isNull(obj[this.nullableField]);
    } else {
      return !type.isNull(obj[this.nullableField]);
    }
  };
}

export { NullableIfRule };
