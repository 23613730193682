import { RULE_NAMES } from "../constants/rule_names";

import { Rule } from "./Rule";

import { IValidationSpec, ValidationData } from "../validator/interfaces";

class EqualsRule extends Rule {
  static get ruleName() {
    return RULE_NAMES.EQUALS;
  }

  get params(): string[] {
    return [this.value];
  }
  value: string;

  constructor(params: { alias: string; spec: IValidationSpec; value: string }) {
    super(params);
    this.value = params.value;
  }

  static parse(
    alias: string,
    spec: IValidationSpec,
    paramsString: string
  ): Rule {
    return new this({ alias, spec, value: paramsString });
  }

  apply = (data: ValidationData): boolean => {
    return data.toString() === this.value;
  };
}

export { EqualsRule };
