import { BetweenRule } from "../rules/BetweenRule";
import { MaxRule } from "../rules/MaxRule";
import { MinRule } from "../rules/MinRule";

import { TYPE_NAMES } from "../constants/type_names";

import { Type } from "./Type";

class FloatType extends Type {
  static get _supportedRules(): Set<string> {
    return new Set([MinRule.ruleName, MaxRule.ruleName, BetweenRule.ruleName]);
  }

  static get typeName(): string {
    return TYPE_NAMES.FLOAT;
  }

  static check(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    return typeof value === "number" && !Number.isNaN(value);
  }
}

export { FloatType };
