import { RULE_NAMES } from "../constants/rule_names";

import { Rule } from "./Rule";

import { ValidationData } from "../validator/interfaces";

class AlphaDashRule extends Rule {
  static get ruleName() {
    return RULE_NAMES.ALPHA_DASH;
  }

  apply = (data: ValidationData): boolean => {
    const value = data as string;
    return /^[A-Za-z0-9-_]+$/.test(value);
  };
}

export { AlphaDashRule };
