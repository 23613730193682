import { AlphaDashRule } from "../rules/AlphaDashRule";
import { AlphaNumRule } from "../rules/AlphaNumRule";
import { AlphaRule } from "../rules/AlphaRule";
import { DecimalRule } from "../rules/DecimalRule";
import { HexRule } from "../rules/HexRule";
import { RegexRule } from "../rules/RegexRule";

import { TYPE_NAMES } from "../constants/type_names";

import { SequenceType } from "./SequenceType";

class StringType extends SequenceType {
  static get _supportedRules(): Set<string> {
    return new Set([
      AlphaNumRule.ruleName,
      AlphaRule.ruleName,
      AlphaDashRule.ruleName,
      DecimalRule.ruleName,
      HexRule.ruleName,
      RegexRule.ruleName,
    ]);
  }

  static get _nullValues() {
    return [""];
  }

  static get typeName(): string {
    return TYPE_NAMES.STRING;
  }

  static check(value: any): boolean {
    return typeof value === typeof "string";
  }
}

export { StringType };
