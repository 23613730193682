import { RULE_NAMES } from "../constants/rule_names";

import { Rule } from "./Rule";

import { ValidationData } from "../validator/interfaces";

class AlphaNumRule extends Rule {
  static get ruleName() {
    return RULE_NAMES.ALPHA_NUM;
  }

  apply = (data: ValidationData): boolean => {
    const value = data as string;
    return /^[A-Za-z0-9]+$/.test(value);
  };
}

export { AlphaNumRule };
