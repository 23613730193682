import XRegExp from "xregexp";

import { TYPE_NAMES } from "../constants/type_names";

import { StringType } from "./StringType";

class UuidType extends StringType {
  static get _supportedRules(): Set<string> {
    return new Set([]);
  }

  static get typeName(): string {
    return TYPE_NAMES.UUID;
  }

  static check(value: any): boolean {
    if (!super.check(value)) {
      return false;
    }
    const regex = XRegExp(
      `
    ^
    [0-9A-F]{8}
    -
    [0-9A-F]{4}
    -
    4
    [0-9A-F]{3}
    -
    [89AB]
    [0-9A-F]{3}
    -
    [0-9A-F]{12}
    $
    `,
      "xi"
    );
    return regex.test(value);
  }
}

export { UuidType };
