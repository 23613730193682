import {
  IValidationArrayData,
  IValidationObjectData,
  ValidationData,
} from "../validator/interfaces";

function stripUndefinedFields(data: ValidationData): ValidationData {
  if (data instanceof Array) {
    const arrayData = data as IValidationArrayData;
    return arrayData.reduce(
      (acc: ValidationData[], val) => {
        return acc.concat([stripUndefinedFields(val)]);
      },
      [] as ValidationData[]
    );
  } else if (data instanceof Object) {
    const objectData = data as IValidationObjectData;
    return Object.keys(objectData).reduce(
      (acc, key) => {
        if (objectData[key] !== undefined) {
          acc[key] = stripUndefinedFields(objectData[key]);
        }
        return acc;
      },
      {} as IValidationObjectData
    );
  } else {
    return data;
  }
}

export { stripUndefinedFields };
