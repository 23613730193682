import { RULE_NAMES } from "../constants/rule_names";

import { Rule } from "./Rule";

import { ValidationData } from "../validator/interfaces";

class HexRule extends Rule {
  static get ruleName() {
    return RULE_NAMES.HEX;
  }

  apply = (data: ValidationData): boolean => {
    const value = data as string;
    return /^[A-Fa-f0-9]+$/.test(value);
  };
}

export { HexRule };
