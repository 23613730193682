import { validate } from "./validate";

function asISO8601Date(
  name: string,
  value: any,
  rules: string | string[] = []
): string {
  const schema = {
    type: "ISO_8601_date",
    rules: rules,
  };
  return validate(name, value, schema, "");
}

export { asISO8601Date };
