import { stripUndefinedFields } from "../utils/undefinedFields";
import { BaseValidator } from "./BaseValidator";

import {
  IValidationArrayData,
  IValidationArraySpec,
  IValidationSpec,
  ValidationData,
} from "./interfaces";

import { ValidatorFactory } from "./ValidatorFactory";

import { ArrayValidationOutcome } from "./ArrayValidationOutcome";

import { ArrayType } from "../types/ArrayType";

class ArrayValidator extends BaseValidator {
  errors: ArrayValidationOutcome;

  constructor(spec: IValidationSpec) {
    super(spec);
    this.errors = new ArrayValidationOutcome();
  }

  validate(data: ValidationData, strict: boolean = false): boolean {
    let success = super.validate(data, strict);

    data = stripUndefinedFields(data);

    if (!ArrayType.check(data) || ArrayType.isNull(data)) {
      return success;
    }

    const spec = this.spec as IValidationArraySpec;

    const validator = ValidatorFactory.make(spec.elements);

    const dataArray = data as IValidationArrayData;

    for (const element of dataArray) {
      const valid = validator.validate(element, strict);

      if (!valid) {
        success = false;
      }

      this.errors.addArrayErrors([validator.errors]);
    }

    return success;
  }
}

export { ArrayValidator };
