import { Rule } from "./Rule";

import { IValidationSpec, ValidationData } from "../validator/interfaces";

import { getFormat } from "../utils/Base64EncodedFileUtils";

class FileFormatRule extends Rule {
  static get ruleName(): string {
    return "file_format";
  }

  get params(): string[] {
    return [this.formats.join(",")];
  }

  formats: string[];

  constructor(params: {
    alias: string;
    formats: string[];
    spec: IValidationSpec;
  }) {
    super(params);
    this.formats = params.formats;
  }

  static parse(
    alias: string,
    spec: IValidationSpec,
    paramsString: string
  ): Rule {
    return new this({ alias, formats: paramsString.split(","), spec });
  }

  apply = (data: ValidationData): boolean => {
    const value = data as string;
    return this.formats.includes(getFormat(value));
  };
}

export { FileFormatRule };
