import { Rule } from "../rules/Rule";

import { RuleFactory } from "../rules/RuleFactory";
import { TypeFactory } from "../types/TypeFactory";
import { stripUndefinedFields } from "../utils/undefinedFields";

import { IValidationSpec, ValidationData } from "./interfaces";

import { ValidationError } from "./ValidationError";

import { BaseValidationOutcome } from "./BaseValidationOutcome";

import { NullableRule } from "../rules/NullableRule";

class BaseValidator {
  spec: IValidationSpec;
  errors: BaseValidationOutcome;

  constructor(spec: IValidationSpec) {
    this.spec = spec;
    this.errors = new BaseValidationOutcome();
  }

  static splitRules(rules: string | string[]): string[] {
    let res = rules;
    if (typeof res === "string") {
      res = res.split("|");
    }
    return res;
  }

  isNullable(): boolean {
    const cls = this.constructor as typeof BaseValidator;
    const rules = cls.splitRules(this.spec.rules);
    return rules.includes(NullableRule.ruleName);
  }

  validate(data: ValidationData, strict: boolean = false): boolean {
    this.errors.empty();

    data = stripUndefinedFields(data);

    const spec = this.spec;

    const cls = this.constructor as typeof BaseValidator;

    const paramType = TypeFactory.get(spec.type);

    const rules = cls.splitRules(spec.rules);

    const mappedRules = rules.map(rule => RuleFactory.create(rule, spec));

    for (const rule of mappedRules) {
      const ruleType = rule.constructor as typeof Rule;
      if (!paramType.supports(ruleType.ruleName)) {
        throw new Error(
          `Rule ${ruleType.ruleName}
            not supported by ${paramType.typeName}`
        );
      }
    }

    if (paramType.isNull(data)) {
      if (this.isNullable()) {
        return true;
      } else {
        this.errors.addError(new ValidationError(NullableRule.ruleName, []));
        return false;
      }
    }

    if (!paramType.check(data)) {
      this.errors.addError(new ValidationError("type", [paramType.typeName]));
      return false;
    }

    let success = true;

    for (const rule of mappedRules) {
      if (!rule.apply(data)) {
        success = false;
        this.errors.addError(new ValidationError(rule.alias, rule.params));
      }
    }

    return success;
  }
}

export { BaseValidator };
