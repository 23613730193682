import { PostProcessHook, PreProcessHook } from "./types";

import { SocketObject } from "./SocketObject";

class SocketHandler {
  static get responseClass(): typeof SocketObject {
    throw new Error("Method not implemented");
  }

  static get preProcessHooks(): PreProcessHook[] {
    return [];
  }

  static get postProcessHooks(): PostProcessHook[] {
    return [];
  }
}

export { SocketHandler };
