import { BaseValidationOutcome } from "./BaseValidationOutcome";

import { ValidationOutcomeJson } from "./interfaces";

class ArrayValidationOutcome extends BaseValidationOutcome {
  elementsErrors: BaseValidationOutcome[] = [];

  merge(outcome: BaseValidationOutcome): void {
    super.merge(outcome);
    if (outcome instanceof ArrayValidationOutcome) {
      this.addArrayErrors(outcome.elementsErrors);
    }
  }

  addArrayErrors = (arrayErrors: BaseValidationOutcome[]): void => {
    this.elementsErrors = this.elementsErrors.concat(arrayErrors);
  };

  toJson(): ValidationOutcomeJson {
    return {
      ...super.toJson(),
      elements_errors: this.elementsErrors.map(el => el.toJson()),
    };
  }

  empty(): void {
    super.empty();
    this.elementsErrors = [];
  }
}

export { ArrayValidationOutcome };
