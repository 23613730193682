import { IValidationSpec } from "./interfaces";

import { ArrayValidator } from "./ArrayValidator";
import { BaseValidator } from "./BaseValidator";
import { ObjectValidator } from "./ObjectValidator";

import { TYPE_NAMES } from "../constants/type_names";

class ValidatorFactory {
  static make(spec: IValidationSpec): BaseValidator {
    if (spec.type === TYPE_NAMES.OBJECT) {
      return new ObjectValidator(spec);
    } else if (spec.type === TYPE_NAMES.ARRAY) {
      return new ArrayValidator(spec);
    } else {
      return new BaseValidator(spec);
    }
  }
}

export { ValidatorFactory };
