import { Type } from "./Type";

import { ArrayType } from "./ArrayType";
import { Base58Type } from "./Base58Type";
import { Base64Type } from "./Base64Type";
import { Base64EncodedFileType } from "./Base64EncodedFileType";
import { BooleanType } from "./BooleanType";
import { EmailType } from "./EmailType";
import { FloatType } from "./FloatType";
import { IntegerType } from "./IntegerType";
import { ISO8601DateType } from "./ISO8601DateType";
import { ObjectType } from "./ObjectType";
import { PhoneType } from "./PhoneType";
import { SequenceType } from "./SequenceType";
import { StringType } from "./StringType";
import { UrlType } from "./UrlType";
import { UuidType } from "./UuidType";

class TypeFactory {
  static types = {
    [ArrayType.typeName]: ArrayType,
    [Base58Type.typeName]: Base58Type,
    [Base64Type.typeName]: Base64Type,
    [Base64EncodedFileType.typeName]: Base64EncodedFileType,
    [BooleanType.typeName]: BooleanType,
    [EmailType.typeName]: EmailType,
    [FloatType.typeName]: FloatType,
    [IntegerType.typeName]: IntegerType,
    [ISO8601DateType.typeName]: ISO8601DateType,
    [ObjectType.typeName]: ObjectType,
    [PhoneType.typeName]: PhoneType,
    [SequenceType.typeName]: SequenceType,
    [StringType.typeName]: StringType,
    [UrlType.typeName]: UrlType,
    [UuidType.typeName]: UuidType,
  };

  static get(what: string): typeof Type {
    if (what in this.types) {
      return this.types[what];
    }
    throw new Error(`Unknown type ${what}`);
  }
}

export { TypeFactory };
