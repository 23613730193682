import XRegExp from "xregexp";

import { TYPE_NAMES } from "../constants/type_names";

import { StringType } from "./StringType";

import { FileFormatRule } from "../rules/FileFormatRule";
import { FileTypeRule } from "../rules/FileTypeRule";
import { MaxSizeRule } from "../rules/MaxSizeRule";
import { MinSizeRule } from "../rules/MinSizeRule";

class Base64EncodedFileType extends StringType {
  static get _supportedRules(): Set<string> {
    return new Set([
      FileFormatRule.ruleName,
      FileTypeRule.ruleName,
      MaxSizeRule.ruleName,
      MinSizeRule.ruleName,
    ]);
  }

  static get typeName(): string {
    return TYPE_NAMES.BASE64_ENCODED_FILE;
  }

  static check(value: any): boolean {
    if (!super.check(value)) {
      return false;
    }
    const regex = XRegExp(
      `
    ^
    data:[a-z]+/[a-z]+;base64,
    (?:[A-Za-z0-9+/]{4})*
    (?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?
    $
    `,
      "x"
    );
    return regex.test(value);
  }
}

export { Base64EncodedFileType };
